<template>
    <div class="dropdown">
        <input 
            type="text" 
            class="dropdown__input" 
            v-model="search" 
            @input="searchProjects" 
            :placeholder="placeholder ?? 'Zoek project'"
            autofocus
            name="search"
            ref="searchInput"
            @keydown.down.prevent="focusNext(-1)"
        >
        <div class="dropdown__menu">
            <a 
                v-for="(result, index) in results" 
                :key="result.id"
                :href="'/projects/' + result.id + '/show'" 
                class="dropdown__item" 
                ref="dropdownItems"
                @keydown.down.prevent="focusNext(index)"
                @keydown.up.prevent="focusPrevious(index)"
                :tabindex="index === 0 ? 0 : -1"
            >   
                {{ result.id + ' - ' + result.name }}
            </a>
            <span 
                v-if="search.length > 0 && results.length === 0"
                class="dropdown__item -empty" >
                Geen resultaten
            </span>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {

        props: ['type', 'placeholder'],

        data() {
            return {
                search: '',
                projects: [],
                results: [], 
                isOpen: false
            };
        },

        mounted() {
            this.getProjects();

            // if COmmand F is pressed, focus on the search input
            // window.addEventListener('keydown', (e) => {
            //     if (e.key === 'f' && e.metaKey) {
            //         e.preventDefault();
            //         this.$refs.searchInput.focus();
            //     }
            // });
        },

        watch: {
            // results(newResults) {
            //     if (newResults.length > 0) {
            //         this.$nextTick(() => {
            //             this.$refs.dropdownItems[0]?.focus();
            //         });
            //     }
            // }
        },

        methods: {
            getProjects() {

                if (this.type == 'archived') {
                    axios.get('/projects/get-archived-projects')
                        .then(response => {
                            this.projects = response.data;
                        });
                    return;
                } else {
                    axios.get('/projects/get-search-projects')
                        .then(response => {
                            this.projects = response.data;
                        });
                }
            },

            searchProjects() {
                if (this.search.length > 0) {
                    this.isOpen = true;
                    this.results = this.projects.filter(project => 
                        (project.id + ' - ' + project.name.toLowerCase()).includes(this.search.toLowerCase())
                    );
                } else {
                    this.isOpen = false;
                    this.results = [];
                }
            },

            focusNext(index) {
                const nextIndex = index + 1;
                if (nextIndex < this.results.length) {
                    this.$refs.dropdownItems[nextIndex].focus();
                }
            },

            focusPrevious(index) {
                const prevIndex = index - 1;
                if (prevIndex >= 0) {
                    this.$refs.dropdownItems[prevIndex].focus();
                } else {
                    this.$refs.searchInput.focus();
                }
            }
        }
    }
</script>
