export const quotes = [

  { quote: "Life is like a frikandel, you have to make it special yourself." },

  
    // Motiverende quotes (Motivational)
    { category: "Motiverend", quote: "Success is not the key to happiness. Happiness is the key to success.", language: "Engels" },
    { category: "Motiverend", quote: "Grote daden beginnen met kleine stappen.", language: "Nederlands" },
    { category: "Motiverend", quote: "Don’t watch the clock; do what it does. Keep going.", language: "Engels" },
    { category: "Motiverend", quote: "Val zeven keer, sta acht keer op.", language: "Nederlands" },
    { category: "Motiverend", quote: "Believe you can and you’re halfway there.", language: "Engels" },
    { category: "Motiverend", quote: "Iedere dag is een nieuwe kans om iets groots te bereiken.", language: "Nederlands" },
    { category: "Motiverend", quote: "The only way to do great work is to love what you do.", language: "Engels" },
    { category: "Motiverend", quote: "Geluk is niet iets wat je vindt, maar iets wat je creëert.", language: "Nederlands" },
    { category: "Motiverend", quote: "Your limitation—it’s only your imagination.", language: "Engels" },
    { category: "Motiverend", quote: "Focus niet op de problemen, maar op de mogelijkheden.", language: "Nederlands" },
  
    // Bekende quotes (Famous)
    { category: "Bekend", quote: "The way to get started is to quit talking and begin doing.", hero: "Walt Disney", language: "Engels" },
    { category: "Bekend", quote: "Je bent nooit te oud om een nieuwe droom te dromen.", hero: "C.S. Lewis", language: "Nederlands" },
    { category: "Bekend", quote: "It always seems impossible until it’s done.", hero: "Nelson Mandela", language: "Engels" },
    { category: "Bekend", quote: "Succes is geen toeval, het is hard werken.", hero: "Pelé", language: "Nederlands" },
    { category: "Bekend", quote: "Your time is limited, so don’t waste it living someone else’s life.", hero: "Steve Jobs", language: "Engels" },
    { category: "Bekend", quote: "Het leven is wat er gebeurt terwijl je andere plannen maakt.", hero: "John Lennon", language: "Nederlands" },
    { category: "Bekend", quote: "Strive not to be a success, but rather to be of value.", hero: "Albert Einstein", language: "Engels" },
    { category: "Bekend", quote: "Je kunt de wind niet veranderen, maar je kunt de zeilen anders zetten.", hero: "Aristoteles", language: "Nederlands" },
    { category: "Bekend", quote: "Success usually comes to those who are too busy to be looking for it.", hero: "Henry David Thoreau", language: "Engels" },
    { category: "Bekend", quote: "Wie geen fouten maakt, maakt meestal niets.", hero: "Theodore Roosevelt", language: "Nederlands" },
  
    // Grappige quotes (Funny)
    { category: "Grappig", quote: "Coffee: because adulting is hard.", language: "Engels" },
    { category: "Grappig", quote: "Werk hard, slaap later.", language: "Nederlands" },
    { category: "Grappig", quote: "The first 5 days after the weekend are the hardest.", language: "Engels" },
    { category: "Grappig", quote: "Ik heb een Excel-sheet gemaakt om mijn Excel-sheets te volgen.", language: "Nederlands" },
    { category: "Grappig", quote: "I'm not lazy, I just really enjoy doing nothing.", language: "Engels" },
    { category: "Grappig", quote: "Mijn favoriete werkdag is vrijdagmiddag. Helaas is dat maar één keer per week.", language: "Nederlands" },
    { category: "Grappig", quote: "I could agree with you, but then we’d both be wrong.", language: "Engels" },
    { category: "Grappig", quote: "Als koffie drinken een sport was, had ik nu een gouden medaille.", language: "Nederlands" },
    { category: "Grappig", quote: "I'm on a seafood diet. I see food, and I eat it.", language: "Engels" },
    { category: "Grappig", quote: "Ik ben niet chagrijnig, ik heb gewoon nog geen koffie gehad.", language: "Nederlands" },
  
    // Motiverende quotes (Motivational)
    { category: "Motiverend", quote: "Dream big and dare to fail.", language: "Engels" },
    { category: "Motiverend", quote: "Succes is niet definitief, falen is niet fataal: het is de moed om door te gaan die telt.", language: "Nederlands" },
    { category: "Motiverend", quote: "Don’t stop when you’re tired. Stop when you’re done.", language: "Engels" },
    { category: "Motiverend", quote: "Succes is niet het resultaat van geluk, maar van doorzettingsvermogen.", language: "Nederlands" },
    { category: "Motiverend", quote: "The harder you work for something, the greater you’ll feel when you achieve it.", language: "Engels" },
    { category: "Motiverend", quote: "Denk groot, begin klein, handel nu.", language: "Nederlands" },
    { category: "Motiverend", quote: "Don’t limit your challenges. Challenge your limits.", language: "Engels" },
    { category: "Motiverend", quote: "Wees niet bang om te falen, maar wees bang om het niet te proberen.", language: "Nederlands" },
    { category: "Motiverend", quote: "You don’t have to be great to start, but you have to start to be great.", language: "Engels" },
    { category: "Motiverend", quote: "Falen is een tussenstap op weg naar succes.", language: "Nederlands" },
  
    // Bekende quotes (Famous)
    { category: "Bekend", quote: "Success is not final, failure is not fatal: It is the courage to continue that counts.", hero: "Winston Churchill", language: "Engels" },
    { category: "Bekend", quote: "Wat je ook doet, doe het goed.", hero: "Walt Disney", language: "Nederlands" },
    { category: "Bekend", quote: "The only limit to our realization of tomorrow is our doubts of today.", hero: "Franklin D. Roosevelt", language: "Engels" },
    { category: "Bekend", quote: "De enige weg naar succes is volharding.", hero: "Vince Lombardi", language: "Nederlands" },
    { category: "Bekend", quote: "Act as if what you do makes a difference. It does.", hero: "William James", language: "Engels" },
    { category: "Bekend", quote: "Je mist 100% van de kansen die je niet neemt.", hero: "Wayne Gretzky", language: "Nederlands" },
    { category: "Bekend", quote: "Do what you can, with what you have, where you are.", hero: "Theodore Roosevelt", language: "Engels" },
    { category: "Bekend", quote: "Verandering is de enige constante.", hero: "Heraclitus", language: "Nederlands" },
    { category: "Bekend", quote: "If you can dream it, you can do it.", hero: "Walt Disney", language: "Engels" },
    { category: "Bekend", quote: "De reis van duizend mijlen begint met een enkele stap.", hero: "Laozi", language: "Nederlands" }
  ];
  
  // Voeg hier nog meer quotes toe tot je 200 hebt!
  