<template>
    <div>
        <div>
            <a class="button text-white" @click="copyIps()">Kopieer de gevaarlijke ip adressen</a>
            <a class="button text-white" @click="updateAllIps()">Update alle Ip adressen</a>
        </div>
        <div>
            <table>
                <tr>
                    <th></th>
                    <th>Ip adres</th>
                    <th>Aantal checks</th>
                    <th>Veilig</th>
                    <th>Ongeldig ip</th>
                    <th @click="sort('failed')" class="link">Onveilig <i v-if="currentSortDir === 'asc'" class="material-icons">arrow_drop_up</i><i v-else class="material-icons">arrow_drop_down</i></th>
                    <th>Laatste update</th>
                    <th></th>
                    <th></th>
                </tr>
                <ip-item v-on:reload="getAllIps()" v-for="(item, index) in sortedIps" :loadingAll="loading" :item="item"
                         :key="index"></ip-item>
                <tr>
                    <td></td>
                    <td><input id="new-ip" type="text" class="form-control form-control-lg mt-0" placeholder="Ip adres">
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <button type="submit" v-on:click="createIp()" class="button text-white">Toevoegen</button>
                    </td>
                    <td></td>
                </tr>
            </table>
        </div>
        <div>
            <div class="table_box">
                <div class="controls domain_controls">
                    <input type="text" v-model="search" placeholder="Filter" class="filter table_filter">
                </div>
                <div class="domain_controls">
                    <div class="left">
                        <input type="text" id="new_domain" placeholder="Nieuw domein"
                               class="form-control form-control-lg mt-0">
                    </div>
                    <div class="right">
                        <button @click="addDomain()" class="button -new not-fixed">+</button>
                    </div>
                </div>
            </div>
            <div class="card-wrapper">
                <div v-for="domain in filteredList" class="card">
                    <h1>{{ domain }}</h1>
                    <i class="material-icons cancel" @click="deleteDomain(domain)">cancel</i>
                </div>
            </div>
        </div>
        <v-dialog/>
    </div>

</template>

<script>
    export default {
        name: "ip-list",
        props: ['ips'],
        data: function () {
            return {
                list: [],
                loading: false,
                ip: "",
                domains: [],
                search: "",
                currentSortDir: 'desc'
            }
        },
        methods: {
            getAllIps: function () {
                axios.get('/ip/all')
                    .then(response => {
                        this.list = response.data
                    });
            },
            getDomains: function () {
                axios.get('/ip/domains')
                    .then(response => {
                        this.domains = response.data;
                    });
            },
            updateDomains: function () {
                axios.put('/ip/domains', {
                    domains: this.domains
                }).then(() => {
                    this.getDomains();
                });
            },
            updateAllIps: function () {
                this.loading = true;
                axios.put('/ip/all')
                    .then(() => {
                        this.getAllIps();
                        this.loading = false;
                    });
            },
            copyIps: function () {
                let el = document.createElement('textarea');
                el.value = this.ips;
                el.setAttribute('readonly', '');
                el.style = {position: 'absolute', left: "-9999px"};
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
            },
            createIp: function () { // 79.137.46.233
                let ip = document.getElementById("new-ip").value;

                if (this.checkIp(ip)) {
                    axios.post('/ip/' + ip)
                        .then(() => {
                            this.getAllIps();
                            document.getElementById('new-ip').value = "";
                        });
                }
            },
            checkIp: function (s) {
                const match = s.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/);
                return match != null &&
                    match[1] <= 255 && match[2] <= 255 &&
                    match[3] <= 255 && match[4] <= 255;
            },
            deleteDomain: function (domain) {
                this.$modal.show('dialog', {
                    title: 'Wil je ' + domain + ' echt verwijderen?',
                    text: 'Deze actie kan niet ongedaan gemaakt worden.',
                    buttons: [
                        {
                            title: 'Ja',
                            handler: () => {
                                this.filteredList.splice(this.filteredList.indexOf(domain), 1);
                                this.domains.splice(this.domains.indexOf(domain), 1);
                                this.updateDomains();

                                this.$modal.hide('dialog');
                            }
                        },
                        {
                            title: 'Annuleer',
                            default: true
                        }
                    ]
                });
            },
            addDomain: function () {
                const domain = document.getElementById('new_domain');
                let value = domain.value;
                if (value !== "") {
                    this.domains.push(value);
                    this.updateDomains();
                    domain.value = "";
                }
            },
            sort: function () {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
        },
        beforeMount() {
            this.getAllIps();
            this.getDomains();
        },
        computed: {
            filteredList() {
                return this.domains.filter(domain => {
                    return domain.toLowerCase().includes(this.search.toLowerCase())
                })
            },
            sortedIps: function () {
                return this.list.sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a['failed'] < b['failed']) return -1 * modifier;
                    if (a['failed'] > b['failed']) return modifier;
                    return 0;
                });
            }
        }
    }
</script>

<style scoped>
    th:nth-child(1), th:nth-child(7), th:nth-child(8), td:nth-child(1), td:nth-child(7), td:nth-child(8) {
        text-align: center;
    }

    .card {
        box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
        margin: 12px;
        display: inline-table;
        vertical-align: middle;
        padding: 10px;
    }

    .card h1 {
        font-size: 16px;
        float: left;
        font-family: "Proxima Nova W05 Light";
        line-height: 30px;
        margin-bottom: 0;
        margin-right: 10px;
    }

    .card i {
        color: red;
        font-size: 30px;
        transform: scale(0);
        transition: all .5s ease;
        cursor: pointer;
        float: right;
    }

    .card:hover i {
        transform: scale(1);
    }

    .card-wrapper {
        margin: 0 -14px;
        text-align: center;
    }

    .controls {
        display: inline-block !important;
    }

    .button.add {
        display: inline-block;
        width: 50px;
        height: 50px;
        padding: 0;
        cursor: pointer;
        font-size: 20px;
    }

    .table_box {
        display: flex;
        justify-content: space-between;
    }

    .domain_controls {
        display: inline-block;
    }

    .domain_controls div {
        display: inline-block;
    }

    .domain_controls div.right {
        margin-left: 20px;
    }

    .link {
        cursor: pointer;
    }
</style>
