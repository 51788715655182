// import { createApp } from 'vue';

// import VModal from "vue-js-modal";
// Vue.use(VModal, { dialog: true });

import { createApp } from 'vue';

import SettingsComponent from './components/partials/settings.vue';
import searchSelect from './components/SearchSelect.vue';
import lazyload from './components/partials/lazyload.vue';
import progressbar from './components/partials/progressbar.vue';
import search from './components/search.vue';
import moment from 'moment';

import { quotes } from './models/quotes';
import { tips } from './models/tips';

require("./bootstrap");
require("./settings");
require("./project");
require("./popup");
require("./hamburger");

// Create Vue app instance
const app = createApp({
    components: {
        search,
        lazyload,
        progressbar,
        searchSelect,
    },
    data() {
      return {

        options: {},
        selected: null,
        selectedCustomer: {
          name: 'Kies een klant',
          id: 0,
        },
        quotes: quotes,
        tips: tips,

        quote: '',
        quoteHero: '',
      };
    },
  
    mounted() {
    //   console.log(this.greeting());
        this.generateRandomQuote();
    },
  
    methods: {
      setSelected(value) {
        window.location = '/projects/' + value.id + '/show';
      },
      setCustomer() {
        console.log(this.selectedCustomer);
      },

      greeting() {
        let hours = Number(moment().format('H'));
  
        if (hours >= 0 && hours < 12) {
          return 'Goedemorgen';
        } else if (hours >= 12 && hours <= 18) {
          return 'Goedemiddag';
        } else {
          return 'Goedenavond';
        }
      },

      generateRandomQuote() {
        let quote = this.quotes[Math.floor(Math.random() * this.quotes.length)];
        if (quote) {
          this.quote = quote.quote;
        }

        if (quote.hero) {
          this.quoteHero = quote.hero;
        }
      },

      randomTip() {
        return this.tips[Math.floor(Math.random() * this.tips.length)];
      }
    },
  });

// Register components globally
// app.component('v-select', VueSelect);
app.component('passport-clients', require('./components/passport/Clients.vue').default);
app.component('passport-authorized-clients', require('./components/passport/AuthorizedClients.vue').default);
app.component('passport-personal-access-tokens', require('./components/passport/PersonalAccessTokens.vue').default);
app.component('settings', SettingsComponent);
app.component('ip-list', require('./components/ip-list.vue').default);
app.component('ip-item', require('./components/ip-item.vue').default);
app.component('customer-list', require('./components/customer-list.vue').default);
app.component('customer-view', require('./components/customer-view.vue').default);
app.component('customer-edit', require('./components/customer-edit.vue').default);

// Use Vue plugins
// app.use(VModal, { dialog: true });

// Mount the app
app.mount('#website');

window.addEventListener("load", () => {

    const modalOpeners = document.querySelectorAll('.open-modal');

    modalOpeners.forEach((opener) => {
        opener.addEventListener('click', () => {
            const modal = document.querySelector('.modal[data-modal="' + opener.dataset.modal + '"]');
            modal.classList.add('-active');

            const modalClose = modal.querySelector('.modal__close');

            modalClose.addEventListener('click', () => {
                modal.classList.remove('-active');
            });
        });
    });


// $('input[name="startdate"], input[name="enddate"]').change(function() {
//     $("#dateform").submit();
// });

$("input.time_user").each(function() {
    if ($(this).is(":checked")) {
        $(this)
            .parents()
            .addClass("checked");
    } else {
        $(this)
            .parents()
            .removeClass("checked");
    }
});

$(document).ready(function() {
    $("input[name=type]").on("change", function() {
        if ($("#type2").is(":checked")) {
            $("#contract_extra").show();
        } else {
            $("#contract_extra").hide();
        }
    });
});

//weekfilter

let moment = require("moment");

moment.locale();
moment().format("L");

if (
    $(".user_form .startdate").val() === "" &&
    $(".user_form .enddate").val() === ""
) {
    thisWeek();
}
 
$(".filter #thisWeek").click(function() {
    thisWeek();
});

function thisWeek() {
    let startOfWeek = moment()
        .startOf("week")
        .format("YYYY-MM-DD");
    let endOfWeek = moment()
        .endOf("week")
        .format("YYYY-MM-DD");

    $(".startdate").val(startOfWeek);
    $(".enddate").val(endOfWeek);

    $("#dateform").submit();
}

$(".filter .-right").click(function() {
    let startOfWeek = moment($(".startdate").val())
        .add(7, "days")
        .format("YYYY-MM-DD");
    let endOfWeek = moment($(".enddate").val())
        .add(7, "days")
        .format("YYYY-MM-DD");

    $(".startdate").val(startOfWeek);
    $(".enddate").val(endOfWeek);

    $("#dateform").submit();
});

$(".filter .-left").click(function() {
    let startOfWeek = moment($(".startdate").val())
        .subtract(7, "days")
        .format("YYYY-MM-DD");
    let endOfWeek = moment($(".enddate").val())
        .subtract(7, "days")
        .format("YYYY-MM-DD");

    $(".startdate").val(startOfWeek);
    $(".enddate").val(endOfWeek);

    $("#dateform").submit();
});

$(".disabled")
    .parents(".tag")
    .css("background-color", "#e0e2e2!important");
$(".no-times")
    .prev(".controls")
    .css("display", "none");

$(".settings, header .user, .menu p").click(function(e) {
    e.preventDefault();
    e.stopPropagation();
});

$(".menu a, input.search, .search_list a, .search_list, .search").click(
    function(e) {
        e.stopPropagation();
    }
);

$("header .user").click(function() {
    $(".user .menu").toggleClass("active");
});

$(".select p").click(function(e) {
    $(".select1 p").css("color", "black");
    e.preventDefault();
    e.stopPropagation();

    let this_select = $(this)
        .parents()
        .children(".select_box");
    $(".select_box")
        .not(this_select)
        .css("display", "none");
    $(this_select).toggle();
    $(this_select)
        .children(".search")
        .children("input")
        .focus();
    $(this_select)
        .children(".search")
        .children("input")
        .val("");
    $(".option").css("display", "flex");

    if ($(this_select).css("display") === "block") {
        $(this)
            .parents()
            .addClass("active");
    } else {
        $(".select").removeClass("active");
    }
});

$(".customer_change").change(function() {
    let value = $('input[name="klantid"]:checked').attr("data-project_name");
    let value2 = $('input[name="project_id"]:checked').attr(
        "data-project_name"
    );

    if (value) {
        $(".select1 p").text(value);
    } else {
        $(".select1 p").text(value2);
    }
});

$(".user").click(function() {
    $('input[name="userid"]')
        .parents(".user")
        .removeClass("checked");
    $('input[name="userid"]:checked')
        .parents(".user")
        .addClass("checked");
});

$(".activity_change").change(function() {
    let value2 = $('input[name="activityid"]:checked').attr(
        "data-activity_name"
    );
    $(".select2 p").text(value2);
});

// $('input[name="time"]').blur(function() {
//     let value = $(this).val();
//     value = value.split(":");

//     let value_hours;
//     let value_minutes;

//     if (value[0]) {
//         value_hours = value[0];
//     } else {
//         value_hours = "00";
//     }

//     if (value[1]) {
//         value_minutes = value[1];
//     } else {
//         value_minutes = "00";
//     }

//     $(this).val(value_hours + ":" + value_minutes);
// });

$('input[name="home_search"]')
    .focus(function() {
        $(this)
            .next(".list")
            .css("display", "block");
    })
    .keyup(function() {
        let value = this.value.toLowerCase();
        let selected_label = $(".filtering");
        let i;

        for (i = 0; i < selected_label.length; i++) {
            let a = selected_label[i].textContent.toLowerCase();

            if (a.indexOf(value) > -1) {
                $(selected_label[i]).css("display", "block");

                if ($(selected_label[i]).hasClass("project")) {
                    $(selected_label[i])
                        .prevAll(".customer")
                        .first()
                        .css("display", "block");
                }

                if ($(selected_label[i]).hasClass("customer")) {
                    $(selected_label[i])
                        .next()
                        .first()
                        .addClass("active");
                }
            } else {
                $(selected_label[i])
                    .not(".no-filtering")
                    .css("display", "none");

                if ($(selected_label[i]).hasClass("customer")) {
                    $(selected_label[i])
                        .next()
                        .first()
                        .removeClass("active");

                    if (
                        $(selected_label[i])
                            .nextUntil(".customer")
                            .css("display") === "none"
                    ) {
                        $(selected_label[i])
                            .not(".no-filtering")
                            .css("display", "none");
                    } else {
                        $(selected_label[i]).css("display", "block");
                    }
                }

                if ($(selected_label[i]).hasClass("project")) {
                    let PrevCustomer = $(selected_label[i])
                        .prevUntil(".customer")
                        .last()
                        .prev()
                        .first();

                    if ($(PrevCustomer).css("display") === "block") {
                        $(selected_label[i]).css("display", "block");
                    } else {
                        $(selected_label[i])
                            .not(".no-filtering")
                            .css("display", "none");
                    }
                }
            }
        }
    });

$('input[name="website_search"]')
    .focusin(function() {
        $(this)
            .next(".list")
            .css("display", "block");
    })
    .keyup(function() {
        let value = this.value.toLowerCase();
        let selected_label = $(".website-search .filtering");
        let i;

        for (i = 0; i < selected_label.length; i++) {
            let a = selected_label[i].textContent.toLowerCase();

            if (a.indexOf(value) > -1) {
                $(selected_label[i]).css("display", "block");
            } else {
                $(selected_label[i])
                    .not(".no-filtering")
                    .css("display", "none");
            }
        }
    });

$(".filter_input").keyup(function() {
    let value = this.value.toLowerCase();
    let selected_label = $(".option label");
    let selected = $(".option");
    let i;

    for (i = 0; i < selected_label.length; i++) {
        let a = selected_label[i].textContent.toLowerCase();

        if (a.indexOf(value) > -1) {
            $(selected[i]).css("display", "block");

            if ($(selected[i]).hasClass("project")) {
                $(selected[i])
                    .prevAll(".customer")
                    .first()
                    .css("display", "block");
            }

            if ($(selected[i]).hasClass("customer")) {
                $(selected[i])
                    .next()
                    .first()
                    .addClass("active");
            }
        } else {
            $(selected[i])
                .not("no-filtering")
                .css("display", "none");

            if ($(selected[i]).hasClass("customer")) {
                $(selected[i])
                    .next()
                    .first()
                    .removeClass("active");
            }

            if ($(selected[i]).hasClass("customer")) {
                if (
                    $(selected[i])
                        .nextUntil(".customer")
                        .css("display") === "none"
                ) {
                    $(selected[i]).css("display", "none");
                } else {
                    $(selected[i]).css("display", "block");
                }
            }

            if ($(selected[i]).hasClass("project")) {
                let PrevCustomer = $(selected[i])
                    .prevUntil(".customer")
                    .last()
                    .prev()
                    .first();

                if ($(PrevCustomer).css("display") === "block") {
                    $(selected[i]).css("display", "block");
                } else {
                    $(selected[i]).css("display", "none");
                }
            }
        }
    }
});

$(".table_filter").keyup(function() {
    let value = this.value.toLowerCase();
    let selected_label = $("table tr");
    let i;

    for (i = 0; i < selected_label.length; i++) {
        let a = selected_label[i].textContent.toLowerCase();

        if (a.indexOf(value) > -1) {
            $(selected_label[i]).css("display", "table-row");

            if ($(selected_label[i]).hasClass("project")) {
                $(selected_label[i])
                    .prevAll(".customer")
                    .first()
                    .css("display", "table-row");
            }

            if ($(selected_label[i]).hasClass("customer")) {
                $(selected_label[i])
                    .next()
                    .first()
                    .addClass("active");
            }
        } else {
            $(selected_label[i])
                .not(".no-filtering")
                .css("display", "none");

            if ($(selected_label[i]).hasClass("customer")) {
                $(selected_label[i])
                    .next()
                    .first()
                    .removeClass("active");
            }

            if ($(selected_label[i]).hasClass("customer")) {
                if (
                    $(selected_label[i])
                        .nextUntil(".customer")
                        .css("display") === "none"
                ) {
                    $(selected_label[i])
                        .not(".no-filtering")
                        .css("display", "none");
                } else {
                    $(selected_label[i]).css("display", "table-row");
                }
            }

            if ($(selected_label[i]).hasClass("project")) {
                let PrevCustomer = $(selected_label[i])
                    .prevUntil(".customer")
                    .last()
                    .prev()
                    .first();

                if ($(PrevCustomer).css("display") === "table-row") {
                    $(selected_label[i]).css("display", "table-row");
                } else {
                    $(selected_label[i])
                        .not(".no-filtering")
                        .css("display", "none");
                }
            }
        }

        if ($("tbody").children(":visible").length == 0) {
            $(".emptyTable").css("display", "block");
        } else {
            $(".emptyTable").css("display", "none");
        }
    }

    $(".cross").click(function() {
        $(".table_filter")
            .val("")
            .removeClass("active");
        $("table tr").css("display", "table-row");
        $(".cross").css("display", "none");
        $(".emptyTable").css("display", "none");
    });

    if (value.length === 0) {
        $(".cross").css("display", "none");
        $(".project").removeClass("active");
    } else {
        $(".cross").css("display", "block");
    }
});

$(".annuleren").click(function() {
    window.history.back();
});

//voortgang balkje

$(".filling").each(function() {
    $(this).css("width", "0%");
    let filling = $(this).attr("data-fill");

    if (filling > 90) {
        $(this).addClass("warning");
    }

    if (filling >= 100) {
        $(this).addClass("full");
        filling = 100;
    }

    let transition = filling * 10;

    $(this).css(
        "transition",
        "width " + transition + "ms, background-color 1s ease"
    );
    $(this).css("width", filling + "%");
});

$("header .search input").keyup(function() {
    $(".search_list").css("display", "block");

    let value = this.value.toLowerCase();
    let selected_label = $(".filtering");
    let i;

    for (i = 0; i < selected_label.length; i++) {
        let a = selected_label[i].textContent.toLowerCase();

        if (a.indexOf(value) > -1) {
            selected_label[i].style.display = "block";

            if ($(selected_label[i]).hasClass("customer")) {
                $(selected_label[i])
                    .nextUntil(".customer")
                    .addClass("active");
            }

            if ($(selected_label[i]).hasClass("project")) {
                $(selected_label[i])
                    .prevAll(".customer")
                    .first()
                    .css("display", "block");
            }
        } else {
            selected_label[i].style.display = "none";
            $(selected_label[i]).removeClass("active");
        }
    }
});

//user

    $(".user").each(function() {
        $(this)
            .addClass("disabled")
            .prop("checked", false);
    });

    // let numbers = checked_items;
    // numbers.forEach(myFunction);

    // function myFunction(value) {
    //     if (value) {
    //         const input = document.querySelector('.user input[value="' + value + '"]');
    //         input.parentElement.classList.remove("disabled");
    //     }
    // }

    let user_array = [];

    $("input.user_input")
        .each(function() {
            if (
                !$(this)
                    .parents(".user")
                    .hasClass("disabled")
            ) {
                $(this).prop("checked", true);
            }

            if ($(this).is(":checked")) {
                user_array.push($(this).val());
            }
        })
        .click(function() {
            if ($(this).is(":checked")) {
                $(this)
                    .parents(".user")
                    .removeClass("disabled");
                user_array.push($(this).val());
                $(".user_form_input").val(user_array);
            } else {
                $(this)
                    .parents(".user")
                    .addClass("disabled");
                let index = user_array.indexOf($(this).val());
                if (index > -1) {
                    user_array.splice(index, 1);
                }
                $(".user_form_input").val(user_array);
            }

            $("#dateform").submit();
        });

    $(".user_form_input").val(user_array);

})
