<template>

    <div>
        
        <section class="section">
            <div class="container">
                <div class="part-12 -justify-between">
                    <h1>Klant: {{ customer.name }}</h1>
                    <div class="buttons">
                        <a :href="'/customers/' + customer.id + '/edit'"
                           data-toggle="tooltip" data-placement="top" data-title="website bewerken"
                           class="button -square">
                           <i class="fas fa-pen"></i>
                        </a>
                        <a type="button" :href="'/customers/' + customer.id + '/delete'" 
                            class="button -red -square"
                        >
                            <i class="fas fa-backspace"></i>
                    </a>
                    </div>
                </div>

                <div class="part-6">
                    <div class="card">
                        <div class="card__row">
                            <label class="w-25 mr-2">Adres</label>
                            <span>{{ customer.address }}</span>
                        </div>
                        <div class="card__row">
                            <label class="w-25 mr-2">Postcode</label>
                            <span>{{ customer.postal }}</span>
                        </div>
                        <div class="card__row">
                            <label class="w-25 mr-2">Woonplaats</label>
                            <span>{{ customer.city }}</span>
                        </div>
                        <div class="card__row">
                            <label class="w-25 mr-2">Opmerkingen</label>
                            <span>{{ customer.extra }}</span>
                        </div>
                    </div>
                    <a :href="'/customers/' + customer.id + '/contact-person'" 
                        class="button">
                        Contact persoon toevoegen
                    </a>
                </div>
                <div class="part-6">
                    <div class="card" 
                        v-for="cp in customer.contact_person" 
                        v-if="customer.contact_person.length > 0"
                    >
                        <h5 class="card-title text-muted">{{ cp.first_name }} {{ cp.last_name }}</h5>
                        <br>
                        <span class="card__row">
                            <label>Email</label>
                            <span>{{ cp.email }}</span>
                        </span>
                        <span class="card__row">
                            <label>Email (2)</label>
                            <span>{{ cp.second_email }}</span>
                        </span>
                        <span class="card__row">
                            <label>Telefoon</label>
                            <span>{{ cp.phone }}</span>
                        </span>
                        <span class="card__row">
                            <label>Telefoon (2)</label>
                            <span>{{ cp.second_phone }}</span>
                        </span>
                        <br>
                        <a :href="'/customers/' + customer.id + '/contact-person/' + cp.id">
                            <button type="button" class="button -light">
                                Wijzigen
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section class="section">
            <div class="container">
                <div class="part-12">
                    <div class="title-bar">
                        <h4>Gekoppelde projecten</h4>
                        <a :href="'/projects/create?customer=' + customer.id" class="button">
                            Nieuw project
                        </a>
                    </div>
                    <p v-if="!customer.projects.length"
                        class="-color-light"
                    >
                        Nog geen project gekoppeld
                    </p>
                    <br>
                    <br>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "customer-view",
        props: ['customer_data'],
        data: function () {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                path: window.location.pathname,
                contactPath: window.location.pathname + '/contact-person',
                customer: this.customer_data
            }
        },
        methods: {}
    }
</script>

<style scoped>

</style>
