$('.search').click(function(){
  $(this).addClass('active');
  $(this).children('input').focus();
});

$('.search input').blur(function(){
  $(this).parents('.search').removeClass('active');
  $(this).val('');
}).keydown(function(){
  $('.search_list').css('display', 'block');
});

$('.hamburger').click(function(){
  $(this).toggleClass('active');
  $('.hamburger_menu').toggleClass('active');
});

$(document).click(function() {
  $('.select_box,.list, .search_list').hide();
  $('.user .menu').removeClass('active');
  $('.settings').removeClass('active');

  if ($('.select_box').css('display') === 'none'){
    $('.select').removeClass('active');
  }
});

