<template>
    <div :class="['dropdown', {'-active': isOpen}]">
        <input 
            type="text" 
            class="dropdown__input" 
            v-model="search" 
            @input="searchPosts" 
            @click="selectSearch"
            @focus="openDropdown()"
            @blur="closeDropdown"
            :placeholder="placeholder"
            autofocus
            name="search"
            ref="searchInput"
        >
        <div class="dropdown__menu" v-show="isOpen">
            <span 
                v-for="(result, index) in results" 
                :key="result.id"
                class="dropdown__item" 
                ref="dropdownItems"
                @click="setInput(result)"
            >   
                {{ result.name }}
            </span>
            <span 
                v-if="search.length > 0 && results.length === 0"
                class="dropdown__item -empty">
                Geen resultaten
            </span>
        </div>
        <input type="hidden" :name="name" v-model="selected">
    </div>
</template>

<script>

    export default {
        props: ['posts', 'placeholder', 'name', 'default'],
        data() {
            return {
                search: '',
                results: [], 
                isOpen: false,
                selected: {},
            };
        },

        mounted() {
            if (!this.default) {
            } else {
                this.selected = this.default;
                this.search = this.posts.find(post => post.id === this.default).name;
            }

            this.results = this.posts;
        },

        methods: {

            searchPosts() {
                if (this.search.length > 0) {
                    this.isOpen = true;
                    this.results = this.posts.filter(post => 
                        (post.id + ' - ' + post.name.toLowerCase()).includes(this.search.toLowerCase())
                    );
                } else {
                    this.results = this.posts;
                }
            },

            setInput(result) {
                this.selected = result.id;
                this.search = result.name;
                this.results = [];
                this.isOpen = false;
            },

            selectSearch() {
                this.$refs.searchInput.setSelectionRange(0, this.search.length);
            },

            closeDropdown() {
                setTimeout(() => {
                    this.isOpen = false;
                }, 200);
            },

            openDropdown() {
                if (this.results.length > 0) {
                    this.isOpen = true;
                }
            }
        }
    }
</script>
