<template>
    <tr>
        <td>
            <i v-if="item.failed > 0" class="material-icons error">error_outline</i>
            <i v-else-if="item.invalid > 0" class="material-icons warning">warning</i>
            <i v-else class="material-icons success">done</i>
        </td>

        <td>{{ item.ip }}</td>
        <td>{{ item.count }}</td>
        <td>{{ item.passed }}</td>
        <td>{{ item.invalid }}</td>
        <td>{{ item.failed }}</td>
        <td :title="item.updated_at">{{ item.updated_at !== null ? new Date(item.updated_at).toLocaleTimeString() : '' }}</td>
        <td v-if="!loading && !loadingAll" v-on:click="check(item.ip)" class="link">check</td>
        <td v-else-if="loading || loadingAll">
            <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </td>
        <td v-on:click="remove(item.ip)" class="link">delete</td>
    </tr>
</template>

<script>
    export default {
        name: "ip-item",
        props: ['item', 'loadingAll'],
        data: function () {
            return {
                loading: false
            }
        },
        methods: {
            check: function (ip) {
                this.loading = true;
                axios.put("/ip/" + ip)
                    .then(() => {
                        this.$emit('reload');
                        this.loading = false;
                    })
            },
            remove: function (ip) {
                this.loading = true;
                this.$modal.show('dialog', {
                    title: 'Wil je ' + ip + ' echt verwijderen?',
                    text: 'Deze actie kan niet ongedaan gemaakt worden.',
                    buttons: [
                        {
                            title: 'Ja',
                            handler: () => {
                                axios.delete("/ip/" + ip)
                                    .then(() => {
                                        this.$emit('reload');
                                        this.loading = false;
                                        this.$modal.hide('dialog');
                                    })
                            }
                        },
                        {
                            title: 'Annuleer',
                            default: true,
                            handler: () => {
                                this.loading = false;
                                this.$modal.hide('dialog');
                            }
                        }
                    ]
                });
            }
        }
    }
</script>

<style scoped>
    .link {
        cursor: pointer;
    }

    .error {
        color: red;
    }

    .warning {
        color: orange;
    }

    .success {
        color: green;
    }

    th:nth-child(1), th:nth-child(7), th:nth-child(8), td:nth-child(1), td:nth-child(7), td:nth-child(8) {
        text-align: center;
    }
</style>
