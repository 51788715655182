<template>
    <div class='progress'>
        <div 
            :style='"width: " + percentage + "%; transition: width " + transition + "ms;"'
            :class="['filling', classNames]"
        ></div>
    </div>
</template>

<script>

import axios from 'axios'

export default {
    props: {
        project: {
            type: String,
            required: false,
        },
        user: {
            type: String,
            required: false,
        },
        before: {
            type: String,
            required: false,
        },
        after: {
            type: String,
            required: false,
        },
        start: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            percentage: 0,
            classNames: [],
            transition: 0
        }
    },

    mounted() {
        this.loadData();
    },

    methods: {
        loadData() {

            if (this.project) {
                axios.get('/projects/worked-percentage/' + this.project)
                    .then(response => {
                        this.percentage = response.data;
                        this.setFilling();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else if (this.start) {
                this.percentage = parseFloat(this.start);
                this.setFilling();
            }
        },

        setFilling() {
            if (this.percentage >= 100) {
                this.classNames.push('full');
                this.percentage = 100;
            } else if (this.percentage >= 90) {
                this.classNames.push('warning');
            }

            this.transition = this.percentage * 10;
        }
    }
}

</script>