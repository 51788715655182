<template>
    <span class="lazyload">{{ time }}</span>
</template>

<script>

import axios from 'axios';

export default {
    props: ['id', 'data'],
    data() {
        return {
            time: '',
        }
    },
    mounted() {
        this.getData();
    },

    methods: {
        getData() {

            if (this.data == 'project-time') {
                axios.get('/projects/get-project-time/' + this.id)
                    .then(response => {
                        this.time = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else if (this.data == 'project-time-left') {
                axios.get('/projects/get-project-time-left/' + this.id)
                    .then(response => {
                        this.time = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }          
        
    }
}

</script>