<template>
    <div class='settings__box'>
        <div class="settings" :class="{ active: isActive }" @click="toggle">
            <span></span>
            <span></span>
            <span></span>
        </div>

        <div class='settings__menu'>
            <slot name="link"></slot>
        </div>

        <slot name="form"></slot>
    </div>
</template>

<script>
    export default {

        data() {
            return {
                isActive: false
            }
        },


        mounted() {

            $(".show").click(function () {
                $(this).parents('.settings_menu').next().fadeIn(200).css('display', 'flex');
            });


            $(".v--box, .annuleren_remove").click(function () {
                $(".v--box").fadeOut(200);
                $('.settings').removeClass('active');
            });
        },

        methods: {

            toggle() {

                this.isActive = this.isActive === false;
            },

        }
    }
</script>
