const x = document.getElementById("search-project");
let projects = [];
if (x !== null) {
    for (let i = 0; i < x.options.length; i++) {
        projects.push([x.options[i].getAttribute('data-value'), x.options[i].value]);
    }
}

$(document).ready(function () {
    $('#project_input').on('input', function () {
        $('#hidden_project').val(projects[projects.findIndex(x => x[1] === $('#project_input').val())][0]);
    });

    $('.import').on('click', function () {

    });
});
