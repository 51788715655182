<template>
    <div>

        <h1>Klant: {{ customer.name }}</h1>
        <form :action="'/customers/' + customer.id" method="POST">
            <input type="hidden" name="_token" :value="csrf">
            <div class="form__field w-100">
                <label for="name">Naam</label>
                <input type="text" name="name" id="name"
                        placeholder="Naam" :value="customer.name || ''" required/>
            </div>
            <div class="form__field w-100">
                <label for="address">Adres</label>
                <input type="text" name="address" id="address"
                        placeholder="Adres" :value="customer.address || ''"/>
            </div>
            <div class="form__field w-100">
                <label for="postal">Postcode</label>
                <input type="text" name="postal" id="postal"
                        placeholder="Postcode" :value="customer.postal || ''"/>
            </div>
            <div class="form__field w-100">
                <label for="city">Woonplaats</label>
                <input type="text" name="city" id="city"
                        placeholder="Woonplaats" :value="customer.city || ''"/>
            </div>
            <div class="form__field w-100">
                <label for="extra">Opmerkingen</label>
                <textarea name="extra" id="extra"
                            placeholder="Opmerkingen">{{ customer.extra || '' }}</textarea>
            </div>
            <div class='form__submit'>
                <input type='submit' class='button' value='Wijzigen'>
                <a class='cancel' onclick="window.history.back()">Annuleren</a>
            </div>
        </form>

    </div>
</template>

<script>
    export default {
        name: "customer-edit",
        props: ['customer_data'],
        data: function () {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                path: window.location.pathname,
                contactPath: window.location.pathname + '/contact-person',
                customer: this.customer_data
            }
        },
        methods: {
            refresh: function (id) {
                const _this = this;
                axios.get('/contact-person/' + id + '/get').then(function (result) {
                    _this.customer.contact_person[_this.customer.contact_person.findIndex(x => x.id === id)] = result.data;
                });
            },
            prevent: function (event) {
                event.preventDefault();
            },
            updateContactPerson: function (id) {
                const first_name = $('#first_name' + id).val();
                const last_name = $('#last_name' + id).val();
                const phone = $('#phone' + id).val();
                const second_phone = $('#second_phone' + id).val();
                const email = $('#email' + id).val();
                const second_email = $('#second_email' + id).val();

                const _this = this;
                axios.put('/contact-person/' + id, {
                    first_name: first_name,
                    last_name: last_name,
                    phone: phone,
                    second_phone: second_phone,
                    email: email,
                    second_email: second_email
                }).then(function () {
                    _this.refresh(id);
                })
            },
            deleteContactPerson(id) {
                if (confirm('Weer je zeker dat je deze contact persoon wilt verwijderen?'))
                    axios.delete('/contact-person/' + id).then(function () {
                        location.reload();
                    });
            }
        }
    }
</script>

<style scoped>

</style>
