<template>
    <section class="section">
        <div class="container">
            <div class="part-12">
                <div class="title-bar">
                    <h1>Klanten</h1>
                    <a href="/customers/create"
                        class="button"
                    >Toevoegen</a>
                </div>
            </div>
            <div class="part-12">
                <table>
                    <thead>
                    <tr>
                        <th>Klant</th>
                        <th>Contact persoon</th>
                        <th>Aantal projecten</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="customer in customers">
                            <td @click="showId(customer.id)">{{ customer.name }}</td>
                            <td @click="showId(customer.id)">{{ customer.firstContactPerson }}</td>
                            <td @click="showId(customer.id)">{{ customer.projectCount }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        
    </section>
</template>

<script>
    export default {
        name: "customer-list",
        props: ['customers'],
        data: function () {
            return {}
        },
        methods: {
            showId: function (id) {
                window.location.href = window.location.pathname + '/' + id;
            }
        }
    }
</script>

<style scoped>
    tbody tr {
        cursor: pointer;
    }
</style>
