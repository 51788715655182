window.addEventListener('load', function() {
    const popup = document.querySelector('.popup');

    if (popup) {
        const close = popup.querySelector('.popup__close');
        const opener = popup.querySelector('.popup__opener');

        close.addEventListener('click', function() {
            popup.classList.remove('-active');
        });

        opener.addEventListener('click', function() {
            popup.classList.toggle('-active');
        });
    }
});